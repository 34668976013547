<template>
  <div id="lineBox">
    <div class="switchBox">
      <p class="switchTitle">开关设置</p>
      <el-table
        :header-cell-style="{
          background: 'rgb(241 167 149)',
          color: '#000',
          fontWeight: 'bold',
        }"
        :data="tableData"
        style="width: 100%; margin-top: 26px"
      >
        <el-table-column prop="type" label="分类" width="130">
        </el-table-column>
        <el-table-column prop="situation" label="情形" width="130">
        </el-table-column>
        <el-table-column prop="shortMsg" label="短信报警" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.shortMsg" placeholder="请选择">
              <el-option
                v-for="item in configurationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="telMsg" label="电话报警" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.telMsg" placeholder="请选择">
              <el-option
                v-for="item in TelephoneList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="stationAppMsg" label="APP站内信" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.stationAppMsg" placeholder="请选择">
              <el-option
                v-for="item in appList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="pushAppMsg" label="推送APP" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.pushAppMsg" placeholder="请选择">
              <el-option
                v-for="item in pushApplist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="platformMsg" label="平台客服" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.platformMsg" placeholder="请选择">
              <el-option
                v-for="item in Platformlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="noticeRange" label="通知范围" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.noticeRange" placeholder="请选择">
              <el-option
                v-for="item in notice"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
            <span style="margin-left: 5%">{{ scope.row.text }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="180">
          <template slot-scope="scope">
            <el-button
              class="conserve"
              @click="preservation(scope.$index, scope.row)"
              >保存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="threshold">
      <p class="thresholdTitle">阈值设置</p>
      <div style="width: 100%; overflow-x: auto">
        <el-container>
          <el-header>
            <el-row :gutter="0">
              <el-col :span="3"
                ><div class="grid-content bg-purple" id="bg-purples">
                  名称
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content bg-purple" id="bg-purples">
                  正常值
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content bg-purple" id="bg-purples">
                  异常
                </div></el-col
              >
              <el-col :span="4"
                ><div class="grid-content bg-purple" id="bg-purples">
                  严重异常
                </div></el-col
              >
              <el-col :span="3"
                ><div class="grid-content bg-purple" id="bg-purples">
                  操作
                </div></el-col
              >
            </el-row>
          </el-header>
          <el-main>
            <el-form
              :inline="true"
              :model="formInlineTwo"
              class="demo-form-inline"
            >
              <el-row :gutter="0">
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    {{ formInlineTwo.name }}
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineTwo.normalMin"
                        @change="highPressure(formInlineTwo.normalMin)"
                      ></el-input
                      >~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineTwo.normalMax"
                        @change="highPressures(formInlineTwo.normalMax)"
                      ></el-input
                      >次/分</span
                    >
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineTwo.abnormaMin"
                        @change="highPressureTwo(formInlineTwo.abnormaMin)"
                      ></el-input
                      >~<span style="margin: 0 6px">{{
                        formInlineTwo.normalMin
                      }}</span
                      >次/分</span
                    >或
                    <span style="padding: 0 1px"
                      ><span style="margin: 0 6px">{{
                        formInlineTwo.normalMax
                      }}</span
                      >~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineTwo.abnormaMax"
                        @change="highPressureThree(formInlineTwo.abnormaMax)"
                      ></el-input
                      >次/分</span
                    >
                  </div></el-col
                >
                <el-col :span="4"
                  ><div class="grid-content bg-purple">
                    超出预设范围
                  </div></el-col
                >
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    <el-button
                      style="padding: 8px 20px"
                      @click="preservatiTwo"
                      :disabled="presersTwo"
                      >保存</el-button
                    >
                  </div></el-col
                >
              </el-row>
            </el-form>
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
            >
              <el-row :gutter="0">
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    {{ formInline.name }}
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInline.normalMin"
                        @change="numberList(formInline.normalMin)"
                      ></el-input
                      >~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInline.normalMax"
                        @change="numberLists(formInline.normalMax)"
                      ></el-input
                      >%</span
                    >
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInline.abnormaMin"
                        @change="numberListTwo(formInline.abnormaMin)"
                      ></el-input
                      >~<span style="margin: 0 6px">{{
                        formInline.normalMin
                      }}</span
                      >%</span
                    >
                  </div></el-col
                >
                <el-col :span="4"
                  ><div class="grid-content bg-purple">
                    超出预设范围
                  </div></el-col
                >
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    <el-button
                      style="padding: 8px 20px"
                      @click="preservatione"
                      :disabled="presers"
                      >保存</el-button
                    >
                  </div></el-col
                >
              </el-row>
            </el-form>
            <el-form
              :inline="true"
              :model="formInlineThree"
              class="demo-form-inline"
            >
              <el-row :gutter="0">
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    {{ formInlineThree.name }}
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineThree.normalMin"
                        @change="bloodSugar(formInlineThree.normalMin)"
                      ></el-input
                      >~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineThree.normalMax"
                        @change="bloodSugars(formInlineThree.normalMax)"
                      ></el-input
                      >℃</span
                    >
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineThree.abnormaMin"
                        @change="bloodSugarTwo(formInlineThree.abnormaMin)"
                      ></el-input
                      >~<span style="margin: 0 6px">{{
                        formInlineThree.normalMin
                      }}</span
                      >℃</span
                    >或
                    <span style="padding: 0 16px"
                      ><span style="margin: 0 6px">{{
                        formInlineThree.normalMax
                      }}</span
                      >~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineThree.abnormaMax"
                        @change="bloodSugarTwos(formInlineThree.abnormaMax)"
                      ></el-input
                      >℃</span
                    >
                  </div></el-col
                >
                <el-col :span="4"
                  ><div class="grid-content bg-purple">
                    超出预设范围
                  </div></el-col
                >
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    <el-button
                      style="padding: 8px 20px"
                      @click="preservatiThree"
                      :disabled="presersThree"
                      >保存</el-button
                    >
                  </div></el-col
                >
              </el-row>
            </el-form>
            <el-form
              :inline="true"
              :model="formInlineFour"
              class="demo-form-inline"
            >
              <el-row :gutter="0">
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    {{ formInlineFour.name }}
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineFour.normalMin"
                        @change="uricAcid(formInlineFour.normalMin)"
                      ></el-input
                      >~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineFour.normalMax"
                        @change="uricAcids(formInlineFour.normalMax)"
                      ></el-input
                      >小时</span
                    >
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineFour.abnormaMin"
                        @change="uricAcidTwo(formInlineFour.abnormaMin)"
                      ></el-input
                      >~<span style="margin: 0 6px">{{
                        formInlineFour.normalMin
                      }}</span
                      >小时</span
                    >或
                    <span style="padding: 0 16px"
                      ><span style="margin: 0 6px">{{
                        formInlineFour.normalMax
                      }}</span
                      >~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInlineFour.abnormaMax"
                        @change="uricAcidTwos(formInlineFour.abnormaMax)"
                      ></el-input
                      >小时</span
                    >
                  </div></el-col
                >
                <el-col :span="4"
                  ><div class="grid-content bg-purple">
                    超出预设范围
                  </div></el-col
                >
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    <el-button
                      style="padding: 8px 20px"
                      @click="preservatiFour"
                      :disabled="presersFour"
                      >保存</el-button
                    >
                  </div></el-col
                >
              </el-row>
            </el-form>
            <!-- <el-form
            :inline="true"
            :model="formInlineFive"
            class="demo-form-inline"
          >
            <el-row :gutter="0">
              <el-col :span="3"
                ><div class="grid-content bg-purple">
                  {{ formInlineFive.name }}
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content bg-purple">
                  <span style="padding: 0 16px"
                    ><el-input
                      style="width: 60px; margin: 0 6px"
                      placeholder="请输入"
                      v-model="formInlineFive.normalMin"
                      @change="sleep(formInlineFive.normalMin)"
                    ></el-input
                    >~<el-input
                      style="width: 60px; margin: 0 6px"
                      placeholder="请输入"
                      v-model="formInlineFive.normalMax"
                      @change="sleeps(formInlineFive.normalMax)"
                    ></el-input
                    >%</span
                  >
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content bg-purple">
                  <span style="padding: 0 16px"
                    ><el-input
                      style="width: 60px; margin: 0 6px"
                      placeholder="请输入"
                      v-model="formInlineFive.abnormaMin"
                      @change="sleepTwo(formInlineFive.abnormaMin)"
                    ></el-input
                    >~<span style="margin: 0 6px">{{
                      formInlineFive.abnormaMax
                    }}</span
                    >%</span
                  >
                </div></el-col
              >
              <el-col :span="4"
                ><div class="grid-content bg-purple">超出预设范围</div></el-col
              >
              <el-col :span="3"
                ><div class="grid-content bg-purple">
                  <el-button
                    style="padding: 8px 20px"
                    @click="preservatiFive"
                    :disabled="presersFive"
                    >保存</el-button
                  >
                </div></el-col
              >
            </el-row>
          </el-form> -->
          </el-main>
        </el-container>
      </div>
    </div>
    <div class="Functional" style="width: 88%">
      <!-- <p class="FunctionalTitle">功能参数</p>
      <functional
        ref="callFunction"
        :typeOnes="typeOne"
        :typeTwos="typeTwo"
        :typeThrees="typeThree"
        :typeFours="typeFour"
        :typeFives="typeFive"
        :typeSixs="typeSix"
        :typeSixOnes="typeSixOne"
        :typeSixTwos="typeSixTwo"
        :typeSevens="typeSeven"
        :typeSevenOnes="typeSevenOne"
        :typeEights="typeEight"
        :typeEightOnes="typeEightOne"
        :typeEightTwos="typeEightTwo"
        :typeIdOne="typeIdOnes"
        :typeIdTwo="typeIdTwos"
        :typeIdThree="typeIdThrees"
        :typeIdFour="typeIdFours"
        :typeIdFive="typeIdFives"
        :typeIdSix="typeIdSixs"
        :typeIdSixOne="typeIdSixOnes"
        :typeIdSixTwo="typeIdSixTwos"
        :typeIdSeven="typeIdSevens"
        :typeIdSevenOne="typeIdSevenOnes"
        :typeIdEight="typeIdEights"
        :typeIdEightOne="typeIdEightOnes"
      ></functional> -->
    </div>
  </div>
</template>
<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
// import Functional from "./Functional.vue";
export default {
  // components: { Functional },
  props: [
    //这里
    "typeOnes",
    "typeTwos",
    "typeThrees",
    "typeFours",
    "typeFives",
    "typeSixs",
    "typeSixOnes",
    "typeSixTwos",
    "typeSevens",
    "typeSevenOnes",
    "typeEights",
    "typeEightOnes",
    "typeEightTwos",
    //这里
    "typeIdOne",
    "typeIdTwo",
    "typeIdThree",
    "typeIdFour",
    "typeIdFive",
    "typeIdSeven",
    "typeIdSevenOne",
    "typeIdEight",
    "typeIdEightOne",
    "typeIdSix",
    "typeIdSixOne",
    "typeIdSixTwo",
  ],
  data() {
    return {
      typeOne: "",
      typeTwo: "",
      typeThree: "",
      typeFour: "",
      typeFive: "",
      typeSix: "",
      typeSixOne: "",
      typeSixTwo: "",
      typeSeven: "",
      typeSevenOne: "",
      typeEight: "",
      typeEightOne: "",
      typeEightTwo: "",
      typeIdOnes: null,
      typeIdTwos: null,
      typeIdThrees: null,
      typeIdFours: null,
      typeIdFives: null,
      typeIdSixs: null,
      typeIdSixOnes: null,
      typeIdSixTwos: null,
      typeIdSevens: null,
      typeIdSevenOnes: null,
      typeIdEightOnes: null,
      typeIdEights: null,
      formInline: {},
      formInlineTwo: {},
      formInlineThree: {},
      formInlineFour: {},
      formInlineFive: {},
      //开关设置
      tableData: [],
      notice: [],
      configurationList: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      TelephoneList: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      appList: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      pushApplist: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      Platformlist: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      presers: false,
      presersTwo: false,
      presersThree: false,
      presersFour: false,
      presersFive: false,
    };
  },
  watch: {
    //这里
    typeOnes(val) {
      this.typeOne = val;
      console.log(val, "是多少");
      console.log("是多少");
    },
    typeTwos(val) {
      this.typeTwo = val;
    },
    typeThrees(val) {
      this.typeThree = val;
    },
    typeFours(val) {
      this.typeFour = val;
    },
    typeFives(val) {
      this.typeFive = val;
    },
    typeSixs(val) {
      this.typeSix = val;
    },
    typeSixOnes(val) {
      this.typeSixOne = val;
    },
    typeSixTwos(val) {
      this.typeSixTwo = val;
    },
    typeSevens(val) {
      this.typeSeven = val;
    },
    typeSevenOnes(val) {
      this.typeSevenOne = val;
    },
    typeEightOnes(val) {
      this.typeEightOne = val;
    },
    typeEightTwos(val) {
      this.typeEightTwo = val;
    },
    typeEights(val) {
      this.typeEight = val;
    },
    //这里
    typeIdOne(val) {
      this.typeIdOnes = val;
    },
    typeIdTwo(val) {
      this.typeIdTwos = val;
    },
    typeIdThree(val) {
      this.typeIdThrees = val;
    },
    typeIdFour(val) {
      this.typeIdFours = val;
    },
    typeIdFive(val) {
      this.typeIdFives = val;
    },
    typeIdSix(val) {
      this.typeIdSixs = val;
    },
    typeIdSixOne(val) {
      this.typeIdSixOnes = val;
    },
    typeIdSixTwo(val) {
      this.typeIdSixTwos = val;
    },
    typeIdSeven(val) {
      this.typeIdSevens = val;
    },
    typeIdSevenOne(val) {
      this.typeIdSevenOnes = val;
    },
    typeIdEight(val) {
      this.typeIdEights = val;
    },
    typeIdEightOne(val) {
      this.typeIdEightOnes = val;
    },
  },
  created() {
    this.getNotice();
  },
  methods: {
    getNotice() {
      api.getSelectProduct({ dictCode: "noticerangeconf" }).then((res) => {
        // console.log('字典：',res.data);
        this.notice = res.data.result;
      });
    },
    preservation(index, row) {
      this.$confirm("是否保存已修改内容?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.updateSwitchs(row).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      console.log(row, index);
    },
    //修改告警阈值
    preservatione() {
      this.$confirm("是否保存已修改内容?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.updateThresholds(this.formInline).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    preservatiTwo() {
      this.$confirm("是否保存已修改内容?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.updateThresholds(this.formInlineTwo).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    preservatiThree() {
      this.$confirm("是否保存已修改内容?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.updateThresholds(this.formInlineThree).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    preservatiFour() {
      this.$confirm("是否保存已修改内容?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.updateThresholds(this.formInlineFour).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // preservatiFive() {
    //   this.$confirm("是否保存已修改内容?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       apiShout.updateThresholds(this.formInlineFive).then((res) => {
    //         if (res.data.code === 200) {
    //           this.$message({
    //             type: "success",
    //             message: "保存成功!",
    //           });
    //         }
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    numberList(val) {
      debugger;
      this.formInline.normalMin = Number(val);
      Number(this.formInline.normalMin);
      Number(this.formInline.normalMax);
      Number(this.formInline.abnormaMin);
      if (this.formInline.normalMin > this.formInline.normalMax) {
        this.presers = true;
        this.$message.error(
          "请检查正常值低压是否正确，前值不可大于后值，否则无法修改"
        );
      } else if (this.formInline.normalMin < this.formInline.abnormaMin) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presers = true;
      } else {
        this.presers = false;
      }
    },
    numberLists(val) {
      debugger;
      this.formInline.normalMax = Number(val);
      Number(this.formInline.normalMin);
      Number(this.formInline.normalMax);
      // Number(this.formInline.abnormaMax);
      if (this.formInline.normalMin > this.formInline.normalMax) {
        this.$message.error(
          "请检查正常值低压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presers = true;
      } else if (this.formInline.normalMax) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presers = true;
      } else {
        this.presers = false;
      }
    },
    numberListTwo(val) {
      debugger;
      this.formInline.abnormaMin = Number(val);
      Number(this.formInline.normalMin);
      Number(this.formInline.normalMax);
      // Number(this.formInline.abnormaMax);
      Number(this.formInline.abnormaMin);
      if (this.formInline.abnormaMin > this.formInline.normalMin) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presers = true;
      } else {
        this.presers = false;
      }
    },
    // numberListThree(val) {
    //   this.formInline.abnormaMax = Number(val);
    //   Number(this.formInline.normalMax);
    //   Number(this.formInline.abnormaMax);
    //   if (this.formInline.normalMax > this.formInline.abnormaMax) {
    //     this.$message.error(
    //       "请检查异常值是否正确，前值不可大于后值，否则无法修改"
    //     );
    //     this.presers = true;
    //   } else {
    //     this.presers = false;
    //   }
    // },
    highPressure(val) {
      this.formInlineTwo.normalMin = Number(val);
      Number(this.formInlineTwo.normalMin);
      Number(this.formInlineTwo.normalMax);
      Number(this.formInlineTwo.abnormaMin);
      if (this.formInlineTwo.normalMin > this.formInlineTwo.normalMax) {
        this.$message.error(
          "请检查正常值高压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersTwo = true;
      } else if (this.formInlineTwo.normalMin < this.formInlineTwo.abnormaMin) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersTwo = true;
      } else {
        this.presersTwo = false;
      }
    },
    highPressures(val) {
      this.formInlineTwo.normalMax = Number(val);
      Number(this.formInlineTwo.normalMin);
      Number(this.formInlineTwo.normalMax);
      Number(this.formInlineTwo.abnormaMax);
      if (this.formInlineTwo.normalMin > this.formInlineTwo.normalMax) {
        this.$message.error(
          "请检查正常值高压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersTwo = true;
      } else if (this.formInlineTwo.normalMax > this.formInlineTwo.abnormaMax) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersTwo = true;
      } else {
        this.presersTwo = false;
      }
    },
    highPressureTwo(val) {
      debugger;
      this.formInlineTwo.abnormaMin = Number(val);
      Number(this.formInlineTwo.normalMin);
      Number(this.formInlineTwo.abnormaMin);
      if (this.formInlineTwo.abnormaMin > this.formInlineTwo.normalMin) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersTwo = true;
      } else {
        this.presersTwo = false;
      }
    },
    highPressureThree(val) {
      this.formInlineTwo.abnormaMax = Number(val);
      Number(this.formInlineTwo.normalMax);
      Number(this.formInlineTwo.abnormaMax);
      if (this.formInlineTwo.normalMax > this.formInlineTwo.abnormaMax) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersTwo = true;
      } else {
        this.presersTwo = false;
      }
    },
    bloodSugar(val) {
      this.formInlineThree.normalMin = Number(val);
      Number(this.formInlineThree.normalMin);
      Number(this.formInlineThree.normalMax);
      Number(this.formInlineThree.abnormaMin);
      if (this.formInlineThree.normalMin > this.formInlineThree.normalMax) {
        this.$message.error(
          "请检查正常值高压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersThree = true;
      } else if (
        this.formInlineThree.normalMin < this.formInlineThree.abnormaMin
      ) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersThree = true;
      } else {
        this.presersThree = false;
      }
    },
    bloodSugars(val) {
      this.formInlineThree.normalMax = Number(val);
      Number(this.formInlineThree.normalMin);
      Number(this.formInlineThree.normalMax);
      Number(this.formInlineThree.abnormaMax);
      if (this.formInlineThree.normalMin > this.formInlineThree.normalMax) {
        this.$message.error(
          "请检查正常值高压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersThree = true;
      } else if (
        this.formInlineThree.normalMax > this.formInlineThree.abnormaMax
      ) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersThree = true;
      } else {
        this.presersThree = false;
      }
    },
    bloodSugarTwo(val) {
      this.formInlineThree.abnormaMin = Number(val);
      Number(this.formInlineThree.normalMin);
      Number(this.formInlineThree.abnormaMin);
      if (this.formInlineThree.abnormaMin > this.formInlineThree.normalMin) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersThree = true;
      } else {
        this.presersThree = false;
      }
    },
    bloodSugarTwos(val) {
      this.formInlineThree.abnormaMax = Number(val);
      Number(this.formInlineThree.normalMax);
      Number(this.formInlineThree.abnormaMax);
      if (this.formInlineThree.normalMax > this.formInlineThree.abnormaMax) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersThree = true;
      } else {
        this.presersThree = false;
      }
    },
    uricAcid(val) {
      this.formInlineFour.normalMin = Number(val);
      Number(this.formInlineFour.normalMin);
      Number(this.formInlineFour.normalMax);
      Number(this.formInlineFour.abnormaMin);
      if (this.formInlineFour.normalMin > this.formInlineFour.normalMax) {
        this.$message.error(
          "请检查正常值高压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersFour = true;
      } else if (
        this.formInlineFour.normalMin < this.formInlineFour.abnormaMin
      ) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersFour = true;
      } else {
        this.presersFour = false;
      }
    },
    uricAcids(val) {
      this.formInlineFour.normalMax = Number(val);
      Number(this.formInlineFour.normalMin);
      Number(this.formInlineFour.normalMax);
      Number(this.formInlineFour.abnormaMax);
      if (this.formInlineFour.normalMin > this.formInlineFour.normalMax) {
        this.$message.error(
          "请检查正常值高压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersFour = true;
      } else if (
        this.formInlineFour.normalMax > this.formInlineFour.abnormaMax
      ) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersFour = true;
      } else {
        this.presersFour = false;
      }
    },
    uricAcidTwo(val) {
      this.formInlineFour.abnormaMin = Number(val);
      Number(this.formInlineFour.normalMin);
      Number(this.formInlineFour.abnormaMin);
      if (this.formInlineFour.abnormaMin > this.formInlineFour.normalMin) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersFour = true;
      } else {
        this.presersFour = false;
      }
    },
    uricAcidTwos(val) {
      this.formInlineFour.abnormaMax = Number(val);
      Number(this.formInlineFour.normalMax);
      Number(this.formInlineFour.abnormaMax);
      if (this.formInlineFour.normalMax > this.formInlineFour.abnormaMax) {
        this.$message.error(
          "请检查异常值是否正确，前值不可大于后值，否则无法修改"
        );
        this.presersFour = true;
      } else {
        this.presersFour = false;
      }
    },
    // sleep(val) {
    //   this.formInlineFive.normalMin = Number(val);
    //   Number(this.formInlineFive.normalMin);
    //   Number(this.formInlineFive.normalMax);
    //   Number(this.formInlineFive.abnormaMin);
    //   if (this.formInlineFive.normalMin > this.formInlineFive.normalMax) {
    //     this.$message.error(
    //       "请检查正常值高压是否正确，前值不可大于后值，否则无法修改"
    //     );
    //     this.presersFive = true;
    //   } else if (
    //     this.formInlineFive.normalMin < this.formInlineFive.abnormaMin
    //   ) {
    //     this.$message.error(
    //       "请检查异常值是否正确，前值不可大于后值，否则无法修改"
    //     );
    //     this.presersFive = true;
    //   } else {
    //     this.presersFive = false;
    //   }
    // },
    // sleeps(val) {
    //   this.formInlineFive.normalMax = Number(val);
    //   Number(this.formInlineFive.normalMin);
    //   Number(this.formInlineFive.normalMax);
    //   Number(this.formInlineFive.abnormaMax);
    //   if (this.formInlineFive.normalMin > this.formInlineFive.normalMax) {
    //     this.$message.error(
    //       "请检查正常值高压是否正确，前值不可大于后值，否则无法修改"
    //     );
    //     this.presersFive = true;
    //   } else if (
    //     this.formInlineFive.normalMax > this.formInlineFive.abnormaMax
    //   ) {
    //     this.$message.error(
    //       "请检查异常值是否正确，前值不可大于后值，否则无法修改"
    //     );
    //     this.presersFive = true;
    //   } else {
    //     this.presersFive = false;
    //   }
    // },
    // sleepTwo(val) {
    //   this.formInlineFive.abnormaMin = Number(val);
    //   Number(this.formInlineFive.normalMin);
    //   Number(this.formInlineFive.abnormaMin);
    //   if (this.formInlineFive.abnormaMin > this.formInlineFive.normalMin) {
    //     this.$message.error(
    //       "请检查异常值是否正确，前值不可大于后值，否则无法修改"
    //     );
    //     this.presersFive = true;
    //   } else {
    //     this.presersFive = false;
    //   }
    // },
  },
};
</script>
<style lang="less" scoped>
.el-table th.el-table__cell > .cell {
  color: #333;
}
#lineBox {
  padding: 30px;
  height: auto;
  border: 1px solid #9d6052;
  .switchBox {
    width: auto;
    .switchTitle {
      font-size: 28px;
      font-weight: bold;
      .conserve {
        float: right;
        background: none;
        padding: 11px 53px;
        border: 1px solid #000;
        font-size: 16px;
      }
    }
  }
  .threshold {
    width: auto;
    margin-top: 5%;
    .thresholdTitle {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 2%;
      .conserve {
        float: right;
        background: none;
        padding: 11px 53px;
        border: 1px solid #000;
        font-size: 16px;
      }
    }
    .el-header {
      background-color: rgb(241, 167, 149);
      color: #333;
      text-align: center;
      display: block !important;
      height: 45px !important;
      padding: 0 !important;
      // width: 1300px !important;
    }

    .el-main {
      background-color: #fbf8f8;
      color: #333;
      text-align: center;
      line-height: 160px;
      height: auto !important;
      // width: 1300px !important;
    }
  }
  .Functional {
    width: 50%;
    margin-top: 5%;
    .FunctionalTitle {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 2%;
    }
    .el-main {
      background-color: #fbf8f8;
      color: #333;
      text-align: center;
      line-height: 160px;
      height: auto !important;
      width: 1300px !important;
    }
  }
  /deep/ .el-table tr {
    background-color: #fbf8f8 !important;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
    text-align: center;
  }
  /deep/ .el-select {
    width: 60%;
  }
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    font-weight: bold;
  }
  .bg-purples {
    background: rgb(241, 167, 149);
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  /deep/ .el-container.is-vertical {
    overflow: hidden !important;
    overflow-y: hidden !important;
    // overflow-x: scroll !important;
    min-width: 1180px;
  }
  /deep/ .el-table .el-table__cell {
    text-align: center !important;
  }
  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  /deep/ .el-input__icon {
    line-height: 30px;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
    color: #333;
    font-weight: bold;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
/deep/.el-table__header-wrapper {
  background-color: rgb(241, 167, 149) !important;
}
/deep/.el-table__body-wrapper {
  overflow: hidden;
  position: relative;
  background-color: #fbf8f8 !important;
}
/deep/.el-table__body-wrapper {
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}
</style>