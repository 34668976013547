<template>
  <div
    class="canshu"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="医疗设备" name="1">
        <medicalEquipment ref="medicalEquipments"></medicalEquipment>
      </el-tab-pane>
      <el-tab-pane label="安防设备" name="2">
        <Security ref="securitys"></Security>
      </el-tab-pane>
      <el-tab-pane label="适老设备" name="3">
        <suitableOld ref="suitableOlds"></suitableOld>
      </el-tab-pane>
      <el-tab-pane label="呼叫器" name="5">
        <callingDevice
          ref="callingDevices"
          :typeOnes="typeOne"
          :typeTwos="typeTwo"
          :typeThrees="typeThree"
          :typeFours="typeFour"
          :typeFives="typeFive"
          :typeSixs="typeSix"
          :typeSixOnes="typeSixOne"
          :typeSixTwos="typeSixTwo"
          :typeSevens="typeSeven"
          :typeSevenOnes="typeSevenOne"
          :typeEights="typeEight"
          :typeEightOnes="typeEightOne"
          :typeEightTwos="typeEightTwo"
          :typeIdOne="typeIdOne"
          :typeIdTwo="typeIdTwo"
          :typeIdThree="typeIdThree"
          :typeIdFour="typeIdFour"
          :typeIdFive="typeIdFive"
          :typeIdSix="typeIdSix"
          :typeIdSixOne="typeIdSixOne"
          :typeIdSixTwo="typeIdSixTwo"
          :typeIdSeven="typeIdSeven"
          :typeIdSevenOne="typeIdSevenOne"
          :typeIdEight="typeIdEight"
          :typeIdEightOne="typeIdEightOne"
        ></callingDevice>
      </el-tab-pane>
      <el-tab-pane label="机器人" name="4">
        <robot></robot>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import medicalEquipment from "./medicalEquipment.vue";
import Security from "./Security.vue";
import suitableOld from "./suitableOld.vue";
import callingDevice from "./callingDevice.vue";
import robot from "./robot.vue";
import { apiShout } from "../../api1/configShout";
export default {
  components: { medicalEquipment, Security, suitableOld, callingDevice, robot },
  data() {
    return {
      clientHeight: document.body.clientHeight,
      activeName: "1",
      input: "12313dbsgbsg",
      typeOne: "",
      typeTwo: "",
      typeThree: "",
      typeFour: "",
      typeFive: "",
      typeSix: "",
      typeSixOne: "",
      typeSixTwo: "",
      typeSeven: "",
      typeSevenOne: "",
      typeEight: "",
      typeEightOne: "",
      typeEightTwo: "",
      typeIdOne: null,
      typeIdTwo: null,
      typeIdThree: null,
      typeIdFour: null,
      typeIdFive: null,
      typeIdSix: null,
      typeIdSixOne: null,
      typeIdSixTwo: null,
      typeIdSeven: null,
      typeIdSevenOne: null,
      typeIdEightOne: null,
      typeIdEight: null,
    };
  },
  created() {
    apiShout.getArlarmConfs({ type: this.activeName }).then((res) => {
      this.$refs.medicalEquipments.tableData = res.data.result.arlarmSwitch;
      this.$refs.securitys.tableData = res.data.result.arlarmSwitch;
      this.$refs.suitableOlds.tableData = res.data.result.arlarmSwitch;
      this.$refs.callingDevices.tableData = res.data.result.arlarmSwitch;
      for (var i = 0; i < res.data.result.alarmThreshold.length; i++) {
        this.$refs.medicalEquipments.formInline =
          res.data.result.alarmThreshold[1];
        this.$refs.medicalEquipments.formInlineTwo =
          res.data.result.alarmThreshold[0];
        this.$refs.medicalEquipments.formInlineThree =
          res.data.result.alarmThreshold[2];
        this.$refs.medicalEquipments.formInlineFour =
          res.data.result.alarmThreshold[3];
        this.$refs.securitys.formInline = res.data.result.alarmThreshold[i];
        this.$refs.suitableOlds.formInline = res.data.result.alarmThreshold[i];
        this.$refs.callingDevices.formInline =
          res.data.result.alarmThreshold[1];
        this.$refs.callingDevices.formInlineTwo =
          res.data.result.alarmThreshold[0];
        this.$refs.callingDevices.formInlineThree =
          res.data.result.alarmThreshold[2];
        this.$refs.callingDevices.formInlineFour =
          res.data.result.alarmThreshold[3];
        // this.$refs.callingDevices.formInlineFive =
        //   res.data.result.alarmThreshold[4];
      }
      for (var j = 0; j < res.data.result.arlarmFunction.length; j++) {
        this.$refs.callingDevices.$refs.callFunction.form =
          res.data.result.arlarmFunction[0];
        this.typeOne = res.data.result.arlarmFunction[0].value;
        this.typeTwo = res.data.result.arlarmFunction[1].value;
        this.typeThree = res.data.result.arlarmFunction[2].value;
        this.typeFour = res.data.result.arlarmFunction[9].value;
        this.typeFive = res.data.result.arlarmFunction[3].value;
        this.typeSix = res.data.result.arlarmFunction[4].value;
        this.typeSixOne = res.data.result.arlarmFunction[5].value;
        this.typeSixTwo = res.data.result.arlarmFunction[6].value;
        this.typeSeven = res.data.result.arlarmFunction[7].value;
        this.typeSevenOne = res.data.result.arlarmFunction[8].value;
        this.typeEight = res.data.result.arlarmFunction[10].value;
        this.typeEightOne = res.data.result.arlarmFunction[11].value;
        this.typeEightTwo = res.data.result.arlarmFunction[12].value;
        this.typeIdOne = res.data.result.arlarmFunction[0].id;
        this.typeIdTwo = res.data.result.arlarmFunction[1].id;
        this.typeIdThree = res.data.result.arlarmFunction[2].id;
        this.typeIdFour = res.data.result.arlarmFunction[9].id;
        this.typeIdFive = res.data.result.arlarmFunction[3].id;
        this.typeIdSix = res.data.result.arlarmFunction[4].id;
        this.typeIdSixOne = res.data.result.arlarmFunction[5].id;
        this.typeIdSixTwo = res.data.result.arlarmFunction[6].id;
        this.typeIdSeven = res.data.result.arlarmFunction[7].id;
        this.typeIdSevenOne = res.data.result.arlarmFunction[8].id;
        this.typeIdEight = res.data.result.arlarmFunction[10].id;
        this.typeIdEightOne = res.data.result.arlarmFunction[11].id;
      }
    });
    // this.$refs.callingDevices.selectBtn();
    // this.$refs.callingDevices.selectBtnOne();
    // this.$refs.callingDevices.selectBtnTwo();
    // this.$refs.callingDevices.selectBtnThree();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.name, event);
      apiShout.getArlarmConfs({ type: tab.name }).then((res) => {
        this.$refs.medicalEquipments.tableData = res.data.result.arlarmSwitch;
        this.$refs.securitys.tableData = res.data.result.arlarmSwitch;
        this.$refs.suitableOlds.tableData = res.data.result.arlarmSwitch;
        this.$refs.callingDevices.tableData = res.data.result.arlarmSwitch;
        for (var i = 0; i < res.data.result.alarmThreshold.length; i++) {
          this.$refs.medicalEquipments.formInline =
            res.data.result.alarmThreshold[1];
          this.$refs.medicalEquipments.formInlineTwo =
            res.data.result.alarmThreshold[0];
          this.$refs.medicalEquipments.formInlineThree =
            res.data.result.alarmThreshold[2];
          this.$refs.medicalEquipments.formInlineFour =
            res.data.result.alarmThreshold[3];
          this.$refs.securitys.formInline = res.data.result.alarmThreshold[i];
          this.$refs.suitableOlds.formInline =
            res.data.result.alarmThreshold[i];
          this.$refs.callingDevices.formInline =
            res.data.result.alarmThreshold[1];
          this.$refs.callingDevices.formInlineTwo =
            res.data.result.alarmThreshold[0];
          this.$refs.callingDevices.formInlineThree =
            res.data.result.alarmThreshold[2];
          this.$refs.callingDevices.formInlineFour =
            res.data.result.alarmThreshold[3];
          // this.$refs.callingDevices.formInlineFive =
          //   res.data.result.alarmThreshold[4];
        }
        for (var j = 0; j < res.data.result.arlarmFunction.length; j++) {
          this.$refs.callingDevices.$refs.callFunction.form =
            res.data.result.arlarmFunction[0];
          this.typeOne = res.data.result.arlarmFunction[0].value;
          this.typeTwo = res.data.result.arlarmFunction[1].value;
          console.log(this.typeOne, "xindexindexinde");
          this.typeThree = res.data.result.arlarmFunction[2].value;
          this.typeFour = res.data.result.arlarmFunction[9].value;
          this.typeFive = res.data.result.arlarmFunction[3].value;
          this.typeSix = res.data.result.arlarmFunction[4].value;
          this.typeSixOne = res.data.result.arlarmFunction[5].value;
          this.typeSixTwo = res.data.result.arlarmFunction[6].value;
          this.typeSeven = res.data.result.arlarmFunction[7].value;
          this.typeSevenOne = res.data.result.arlarmFunction[8].value;
          this.typeEight = res.data.result.arlarmFunction[10].value;
          this.typeEightOne = res.data.result.arlarmFunction[11].value;
          this.typeEightTwo = res.data.result.arlarmFunction[12].value;
          console.log(this.typeThree, "xindexindexinde11111");
          this.typeIdOne = res.data.result.arlarmFunction[0].id;
          this.typeIdTwo = res.data.result.arlarmFunction[1].id;
          this.typeIdThree = res.data.result.arlarmFunction[2].id;
          this.typeIdFour = res.data.result.arlarmFunction[9].id;
          this.typeIdFive = res.data.result.arlarmFunction[3].id;
          this.typeIdSix = res.data.result.arlarmFunction[4].id;
          this.typeIdSixOne = res.data.result.arlarmFunction[5].id;
          this.typeIdSixTwo = res.data.result.arlarmFunction[6].id;
          this.typeIdSeven = res.data.result.arlarmFunction[7].id;
          this.typeIdSevenOne = res.data.result.arlarmFunction[8].id;
          this.typeIdEight = res.data.result.arlarmFunction[10].id;
          this.typeIdEightOne = res.data.result.arlarmFunction[11].id;
          this.$refs.callingDevices.$refs.callFunction.form.region =
            res.data.result.arlarmFunction[0].value;
          console.log(
            this.$refs.callingDevices.$refs.callFunction.form.region,
            "对对"
          );
          this.$refs.callingDevices.$refs.callFunction.formTwo.dataUpload =
            res.data.result.arlarmFunction[1].value;
          this.$refs.callingDevices.$refs.callFunction.formThree.callPolice =
            res.data.result.arlarmFunction[2].value;
          this.$refs.callingDevices.$refs.callFunction.formFour.Funtime =
            res.data.result.arlarmFunction[9].value;
          this.$refs.callingDevices.$refs.callFunction.formFive.Gps =
            res.data.result.arlarmFunction[3].value;
          this.$refs.callingDevices.$refs.callFunction.formSix.startTime =
            res.data.result.arlarmFunction[4].value;
          this.$refs.callingDevices.$refs.callFunction.formSix.endTime =
            res.data.result.arlarmFunction[5].value;
          this.$refs.callingDevices.$refs.callFunction.formSix.pattern =
            res.data.result.arlarmFunction[6].value;
          this.$refs.callingDevices.$refs.callFunction.formSeven.Sos =
            res.data.result.arlarmFunction[7].value;
          this.$refs.callingDevices.$refs.callFunction.formEight.Call =
            res.data.result.arlarmFunction[10].value;
          this.$refs.callingDevices.$refs.callFunction.formTwo =
            res.data.result.arlarmFunction[1];
          this.$refs.callingDevices.$refs.callFunction.formThree =
            res.data.result.arlarmFunction[2];
          this.$refs.callingDevices.$refs.callFunction.formFour =
            res.data.result.arlarmFunction[9];
          this.$refs.callingDevices.$refs.callFunction.formFive =
            res.data.result.arlarmFunction[3];
          this.$refs.callingDevices.$refs.callFunction.formSeven =
            res.data.result.arlarmFunction[7];
          this.$refs.callingDevices.$refs.callFunction.formEight =
            res.data.result.arlarmFunction[10];
          this.$refs.callingDevices.$refs.callFunction.formSix.name =
            res.data.result.arlarmFunction[4].name;
          this.$refs.callingDevices.$refs.callFunction.formSix.nameOne =
            res.data.result.arlarmFunction[5].name;
          this.$refs.callingDevices.$refs.callFunction.formSix.nameTwo =
            res.data.result.arlarmFunction[6].name;
          // console.log(this.$refs.callingDevice.formSix.startTime,"dui.......")
        }
      });
    },
  },
};
</script>

<style lang="less">
.canshu {
  .el-tabs--card > .el-tabs__header,
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }
  .el-tabs {
    margin: 45px 9px 0 25px;
    // 公共
    .el-tabs__item.is-active {
      color: #9d6052;
      background: url("../../assets/images/tab.png") no-repeat;
      border: 1px solid #9d6052;
    }
    .el-tabs__item:hover {
      color: #9d6052;
    }
    .el-tabs__item {
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      padding: 0 65px;
      color: #d78673;
      border: none;
      border: 1px solid #9d6052;
      border-left: 1px solid #9d6052 !important;
      padding-left: 65px !important;
      padding-right: 65px !important;
      outline: none;
    }
  }
}
</style>