<template>
  <div id="lineBox">
    <div class="switchBox">
      <p class="switchTitle">通知设置</p>
      <el-table
        :header-cell-style="{
          background: 'rgb(241 167 149)',
          color: '#000',
          fontWeight: 'bold',
        }"
        :data="tableData"
        style="width: 100%; margin-top: 26px"
      >
        <el-table-column prop="type" label="分类" width="110">
        </el-table-column>
        <el-table-column prop="situation" label="情形" width="110">
        </el-table-column>
        <el-table-column prop="shortMsg" label="短信报警" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.shortMsg" placeholder="请选择">
              <el-option
                v-for="item in configurationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="telMsg" label="电话报警" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.telMsg" placeholder="请选择">
              <el-option
                v-for="item in TelephoneList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="stationAppMsg" label="APP站内信" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.stationAppMsg" placeholder="请选择">
              <el-option
                v-for="item in appList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span style="margin-left: 5%">{{ scope.row.text }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pushAppMsg" label="手机推送" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.pushAppMsg" placeholder="请选择">
              <el-option
                v-for="item in pushApplist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span style="margin-left: 5%">{{ scope.row.text }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="platformMsg" label="平台客服" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.platformMsg" placeholder="请选择">
              <el-option
                v-for="item in Platformlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span style="margin-left: 5%">{{ scope.row.text }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="noticeRange" label="通知范围" width="180">
          <template slot-scope="scope">
            <el-select v-model="scope.row.noticeRange" placeholder="请选择">
              <el-option
                v-for="item in notice"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
            <span style="margin-left: 5%">{{ scope.row.text }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="180">
          <template slot-scope="scope">
            <el-button
              class="conserve"
              @click="preservation(scope.$index, scope.row)"
              >保存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="threshold">
      <p class="thresholdTitle">阈值设置</p>
      <div style="width: 100%; overflow-x: auto">
        <el-container>
          <el-header>
            <el-row :gutter="0">
              <el-col :span="3"
                ><div class="grid-content bg-purple" id="bg-purples">
                  名称
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content bg-purple" id="bg-purples">
                  正常值
                </div></el-col
              >
              <el-col :span="7"
                ><div class="grid-content bg-purple" id="bg-purples">
                  异常
                </div></el-col
              >
              <el-col :span="4"
                ><div class="grid-content bg-purple" id="bg-purples">
                  严重异常
                </div></el-col
              >
              <el-col :span="3"
                ><div class="grid-content bg-purple" id="bg-purples">
                  操作
                </div></el-col
              >
            </el-row>
          </el-header>
          <el-main>
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
            >
              <el-row :gutter="0">
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    {{ formInline.name }}
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInline.normalMin"
                        @change="numberList"
                      ></el-input
                      ><span style="margin-right: 5px">%</span>~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInline.normalMax"
                        @change="numberLists"
                      ></el-input
                      >%</span
                    >
                  </div></el-col
                >
                <el-col :span="7"
                  ><div class="grid-content bg-purple">
                    <span style="padding: 0 16px"
                      ><el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInline.abnormaMin"
                        @change="numberTwo"
                      ></el-input
                      ><span style="margin-right: 5px">%</span>~<el-input
                        style="width: 60px; margin: 0 6px"
                        placeholder="请输入"
                        v-model="formInline.abnormaMax"
                        @change="numberTwos"
                      ></el-input
                      >%</span
                    >
                  </div></el-col
                >
                <el-col :span="4"
                  ><div class="grid-content bg-purple">
                    超出预设范围
                  </div></el-col
                >
                <el-col :span="3"
                  ><div class="grid-content bg-purple">
                    <el-button
                      style="padding: 8px 20px"
                      @click="preservatione"
                      :disabled="presers"
                      >保存</el-button
                    >
                  </div></el-col
                >
              </el-row>
            </el-form>
          </el-main>
        </el-container>
      </div>
    </div>
  </div>
</template>
<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      input: "20",
      inputs: "100",
      inputOne: "5",
      inputOnes: "20",
      //开关设置
      tableData: [],
      formInline: {},
      configurationList: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      notice: [],
      TelephoneList: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      appList: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      pushApplist: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      Platformlist: [
        {
          id: "0",
          name: "关闭",
        },
        {
          id: "1",
          name: "开启",
        },
      ],
      presers: false,
    };
  },
  created() {
    this.getNotice();
  },
  methods: {
    getNotice() {
      api.getSelectProduct({ dictCode: "noticerangeconf" }).then((res) => {
        // console.log('字典：',res.data);
        this.notice = res.data.result;
      });
    },
    //修改告警开关
    preservation(index, row) {
      this.$confirm("是否保存已修改内容?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.updateSwitchs(row).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      console.log(row, index);
    },
    //修改告警阈值
    preservatione() {
      this.$confirm("是否保存已修改内容?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.updateThresholds(this.formInline).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    numberList(val) {
      debugger;
      this.formInline.normalMin = Number(val);
      Number(this.formInline.normalMin);
      Number(this.formInline.normalMax);
      if (this.formInline.normalMin > this.formInline.normalMax) {
        this.presers = true;
        this.$message.error(
          "请检查正常值低压是否正确，前值不可大于后值，否则无法修改"
        );
      } else {
        this.presers = false;
      }
    },
    numberLists(val) {
      this.formInline.normalMax = Number(val);
      Number(this.formInline.normalMin);
      Number(this.formInline.normalMax);
      if (this.formInline.normalMin > this.formInline.normalMax) {
        this.$message.error(
          "请检查正常值低压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presers = true;
      } else {
        this.presers = false;
      }
    },
    numberTwo(val) {
      debugger;
      this.formInline.abnormaMin = Number(val);
      Number(this.formInline.abnormaMin);
      Number(this.formInline.abnormaMax);
      if (this.formInline.abnormaMin > this.formInline.abnormaMax) {
        this.$message.error(
          "请检查正常值低压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presers = true;
      } else {
        this.presers = false;
      }
    },
    numberTwos(val) {
      this.formInline.abnormaMax = Number(val);
      Number(this.formInline.abnormaMin);
      Number(this.formInline.abnormaMax);
      if (this.formInline.abnormaMin > this.formInline.abnormaMax) {
        this.$message.error(
          "请检查正常值低压是否正确，前值不可大于后值，否则无法修改"
        );
        this.presers = true;
      } else {
        this.presers = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
#lineBox {
  padding: 30px;
  height: auto;
  border: 1px solid #9d6052;
  .switchBox {
    width: auto;
    .switchTitle {
      font-size: 28px;
      font-weight: bold;
      .conserve {
        float: right;
        background: none;
        padding: 11px 53px;
        border: 1px solid #000;
        font-size: 16px;
      }
    }
  }
  .threshold {
    width: auto;
    margin-top: 5%;
    .thresholdTitle {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 2%;
      .conserve {
        float: right;
        background: none;
        padding: 11px 53px;
        border: 1px solid #000;
        font-size: 16px;
      }
    }
    .el-header {
      background-color: rgb(241, 167, 149);
      color: #333;
      text-align: center;
      display: block !important;
      height: 45px !important;
      padding: 0 !important;
    }

    .el-main {
      background-color: #fbf8f8;
      color: #333;
      text-align: center;
      line-height: 160px;
      height: auto !important;
    }
  }
  /deep/ .el-table tr {
    background-color: #fbf8f8 !important;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
    text-align: center;
  }
  /deep/ .el-select {
    width: 58%;
  }
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    font-weight: bold;
  }
  .bg-purples {
    background: rgb(241, 167, 149);
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  /deep/ .el-table .el-table__cell {
    text-align: center !important;
  }
  /deep/ .el-container.is-vertical {
    min-width: 1000px;
  }
  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  /deep/ .el-input__icon {
    line-height: 30px;
  }
  /deep/.el-table th.el-table__cell > .cell {
    color: #333 !important;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
    color: #333;
    font-weight: bold;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
/deep/.el-table__header-wrapper {
  background-color: rgb(241, 167, 149) !important;
}
/deep/.el-table__body-wrapper {
  overflow: hidden;
  position: relative;
  background-color: #fbf8f8 !important;
}
/deep/.el-table__body-wrapper {
  overflow-x: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}
</style>